import * as React from "react"
import MainLayout from "../components/layouts/main-layout"
import ProductCard from "../components/ui/product-card"
import useProducts from "../hooks/use-products"

const location = typeof window !== `undefined` ? window.location : null

const SearchProducts = () => {
  const urlParams = new URLSearchParams(location?.search)
  const query = urlParams.get('q')
  const products = useProducts().getProductsFilter(query)

  return (
    <MainLayout>
      <div className="container pt-3 pb-5">
        <h1 className="pt-5 pb-1 font-monserrat fs-3 text-shadow">Buscando productos con la palabra: <b>{query}</b></h1>
        {products?.length > 0 && (
          <>
            <p className="font-monserrat">Si tienes interés en algún producto, por favor escríbenos para enviarte más información y saber como realizar el proceso de compra.</p>
            <section className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 gx-4 gy-3 mb-5">
              {products?.map(({ id, name, excerpt, slug, image }) => (
                <div className={`col`} key={id}>
                  <ProductCard name={name} excerpt={excerpt} slug={slug} image={image} />
                </div>
              ))}
            </section>
          </>
        )}
        {!products?.length && (
          <section className="products-container">
            <p className="text-danger">Lo sentimos, no existen productos con el parámetro de búsqueda registrado.</p>
          </section>
        )}
      </div>
    </MainLayout>
  )
}

export default SearchProducts